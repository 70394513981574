<template>
  <div class="row itemBox">
      <div class="col-md-2">
          <div class="imagerBox">
              <img :src="`${baseUrl}${bannerEarning.bannerURL}`"/>
          </div>          
      </div>
      <div class="col-md-6 ">
          <div class="discBox">
              <template v-if="bannerEarning.catId">
                <p>{{bannerEarning.catName}}</p>
                <p>Reference count: {{bannerEarning.count}}</p>
            </template>
            <template v-if="bannerEarning.prId">
                <p>{{bannerEarning.prName}}</p>
                <p> {{translate('purchasedBy')}}: {{bannerEarning.custName}}</p>
                <p>{{translate('purchaseDate')}}: {{formatedDate(bannerEarning.purchaseDate)}}</p>
                <p>{{translate('earning')}}: {{currency}} {{bannerEarning.afEarnings}}</p>
            </template>

          </div>
            
            
      </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
name:"AffiliationEarningCard",
props:[
    'translationloaded',
    'translate',
    'bannerEarning',
    'baseUrl',
    'currency'
],
methods:{
     formatedDate(date){
           return moment(date).format('MMMM D, YYYY');
      },
}
}
</script>

<style  scoped>
.imagerBox{
    width:100%;
    /* min-height: 150px; */
    background: #fff;
   
}
.itemBox{
     padding: 10px;    
    border: 1px solid #f1efef;
    border-radius: 5px;
    margin-bottom: 5px;
}
.discBox p{
    font-size: 2rem;
    /* margin-top:20px; */
    margin-bottom:0px;

}
.discBox p:nth-child(2){
    font-size: 1.5rem;
    margin-top:0px
}
.discBox p:nth-child(3){
    font-size: 1.5rem;
    margin-top:0px
}
.discBox p:nth-child(4){
    font-size: 1.5rem;
    margin-top:0px
}
</style>