<template>
    <Breadcrumb :urls="urls"  :translationloaded="translationloaded" :translate="translate"></Breadcrumb>
    <div class="account-info-sec" v-if="translationloaded==true">
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <div id="show-hidden-menu" class="dashBoarsBtnMain"  @click="toggledashboard()">
            <i class="fa fa-th-large" aria-hidden="true"></i>{{translate('dashboard')}}
          </div>
          <div id ="vdasboard"  class="hidden-menu" style="display: none;">
            <SideBar
              :translationloaded="translationloaded"
              :translate="translate"
            ></SideBar>
          </div>
          <div class="mainSideDiplay">
           <SideBar
              :translationloaded="translationloaded"
              :translate="translate"
            ></SideBar>
          </div>
        </div>
        <div class="col-lg-9">
          <template v-if="affiliationEarning!=''">
             <template v-for="(earning,index) in affiliationEarning" :key="index">
               <AffiliationEarningCard :baseUrl="baseUrl" :bannerEarning="earning"
                :translationloaded="translationloaded"
                :translate="translate"
                 :currency="currency"></AffiliationEarningCard>
               
            </template>
          </template>
           <template v-if="ajaxLoad!=true && affiliationEarning.lenght <1">
             <div class="text-center">
                <img  src="/assets/img/no-data-found.png" />
             </div>
          </template>
           
         
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../components/Breadcrumb";
import SideBar from "../components/my_account/SideBar";
import AffiliationEarningCard from "@/components/affiliatesprogram/AffiliationEarningCard";

import axios from "axios";
import Config from "@/config";
import helper from "@/helper/helper";
export default {
name:'AffiliationEarning',
 components: {
        Breadcrumb,
        SideBar,
        AffiliationEarningCard
    },
    props:[
        'translationloaded',
        'translate'
    ],
    mounted(){
        this.getAffiliationEarning();
        this.baseUrl =Config.WEB_ADMIN_URL;
        this.currency =Config.CURRECNCY;
        helper.backtoTop();
    },
    data(){
        return {
             urls: [
                {
                path: "/",
                name: "Home",
                },

                {
                path: "/affiliation-earning",
                name: "affiliationEarnings",
                },
            ],
            affiliationEarning:[],
            baseUrl:'',
            currency:'',
            ajaxLoad:true
        }
    },
    methods:{
      toggledashboard()
    {
       var x = document.getElementById("vdasboard");
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
    },
         getAffiliationEarning(){
            let $this=this;
            axios.get(`${Config.BASE_URL}AFAnalyticsByCustomer?custId=${localStorage.getItem("custId")}`).then(function(response){
                
                $this.affiliationEarning =response.data.Data;
                $this.ajaxLoad=false;
            }).catch(()=>{
              $this.ajaxLoad=false;
            });
        }
    }
}
</script>

<style  scoped>

</style>

